import React from 'react'
import Meta from '../components/Meta'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Header from '../components/Header'
import PageHeading from '../components/PageHeading'
import Footer from '../components/Footer'
import GatsbyImage from 'gatsby-image'
import ContactUs from '../components/ContactUs'
import RichText from '../components/RichText'


class SimplePageRoute extends React.Component {
    render() {
        const simplePageData = this.props.data
        const postfix = simplePageData.kontentItemSiteMetadata.elements.title.value
        const simplePageElements = simplePageData.kontentItemSimplePage.elements
        const pageTitle = simplePageElements.title.value
        const pageSubtitle = simplePageElements.subtitle.value
        const metaDescription = simplePageElements.meta_description.value
        const teaserImage = simplePageElements.teaser_image
        const teaserImageAlignment = simplePageElements.teaser_image_alignment__teaser_image_alignment
        const teaserImageAlignmentValue = teaserImageAlignment.value[0] != null ? teaserImageAlignment.value[0].codename : "middle"
        const layout = simplePageElements.layout.value[0].codename

        return (
            <Layout>
                <div>
                    <Meta title={pageTitle} postfix={postfix} description={metaDescription} />
                    <Header />
                    <PageHeading data={pageSubtitle} />
                    <section className="simple-page services-page">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {layout == "uzky" ? (
                                        <div className="row">
                                            <div className="col-lg-9 order-2">
                                                <section className="tabs-content">
                                                    {teaserImage.value[0] ? (
                                                        <div className={`service-thumb service-thumb--${teaserImageAlignmentValue}`}>
                                                            <GatsbyImage
                                                                fluid={teaserImage.value[0].fluid}
                                                                alt={teaserImage.value[0].description}
                                                                style={{ height: '300px' }}
                                                            />
                                                        </div>
                                                    ) : ""}
                                                    <div className="down-content">
                                                        <RichText data={simplePageElements.description} />
                                                    </div>
                                                </section>
                                            </div>
                                            <div className="col-lg-3 order-1">
                                                <div className="services-sidebar">
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <ContactUs />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : ([
                                        teaserImage.value[0] ? (
                                            <div className={`service-thumb service-thumb--${teaserImageAlignmentValue}`}>
                                                <GatsbyImage
                                                    fluid={teaserImage.value[0].fluid}
                                                    alt={teaserImage.value[0].description}
                                                    style={{ height: '300px' }}
                                                />
                                            </div>
                                        ) : ""
                                            ,
                                            <RichText data={simplePageElements.description} key="rich" />
                                    ]
                                        )}
                                </div>
                            </div>
                        </div>
                    </section>
                    <Footer />
                </div>
            </Layout>
        )
    }
}

export default SimplePageRoute

export const pageQuery = graphql`
  query SimplePageQuery($codename: String!) {
    kontentItemSiteMetadata(system: {codename: {eq: "site_metadata"}}) {
      elements {
        subtitle {
          value
        }
        title {
          value
        }
      }
    }
    kontentItemSimplePage(system: {codename: {eq: $codename}}) {
      id
      system {
        id
      }
      elements {
        title {
          value
        }
        subtitle {
          value
        }
        meta_description {
          value
        }
        layout {
          value {
            codename
          }
        }
        teaser_image {
          value {
            fluid(maxWidth: 810) {
              ...KontentAssetFluid
            }
            name
            url
            description
          }
        }
        teaser_image_alignment__teaser_image_alignment {
          value {
            codename
          }
        }
        description {
          value
          links {
            link_id
            url_slug
            type
          }
          images {
            image_id
            description
            url
            fluid(maxWidth: 250) {
              aspectRatio
              ...KontentAssetFluid
            }
            fixed(height: 700) {
              aspectRatio
              src
              ...KontentAssetFixed
            }
          }
          modular_content {
            ... on kontent_item_component_images_wrapper {
              id
              elements {
                description {
                  value
                }
                photos {
                  value {
                    fluid(maxWidth: 250) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
            ... on kontent_item_component_text_and_image {
              id
              elements {
                text {
                  value
                }
                image {
                  value {
                    fluid(maxWidth: 250) {
                      aspectRatio
                      ...KontentAssetFluid
                    }
                    fixed(height: 700) {
                      aspectRatio
                      src
                      ...KontentAssetFixed
                    }
                    description
                    url
                  }
                }
              }
              system {
                codename
                type
              }
            }
          }           
        }    
      }
    }
  }
`